import React from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import MailchimpForm from "../components/mailchimp_form"
import { css } from "@emotion/core"

export const query = graphql`
  query {
    file(relativePath: { eq: "image1.jpeg" }) {
      childImageSharp {
        fixed(width: 175, height: 175) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
const About = ({ data }) => (
  <Layout>
    <MailchimpForm />
    <div
      css={css`
        width: 100%;
        @media (min-width: 600px) {
          width: 80%;
        }
        margin-right: 40px;
        padding-right: 40px;
      `}
    >
      <h1>About Amy</h1>

      <Img
        className="headshot"
        fixed={data.file.childImageSharp.fixed}
        alt="headshot"
      />

      <br />
      <div>
        <p>
          Hello, I’m Amy. This blog contains articles on how to learn technical topics, important programming skills to develop, and ways to improve your productivity as a programmer.
        </p>

        <p>
        
        I've also written an eBook, <a href="https://a.co/d/1MLhFyX">Problem-Solving for Beginner Programmers Using Python</a>. In it, you'll learn about a four-part process to solve problems, how to get unstuck when you hit a programming wall, and seven approaches you can apply to the problems you solve. Then, put your knowledge to practice with five exercises. 

        </p>
        
    
        <p>
        And I've created a free online learning tool, <a href="https://programmerspyramid.com/">Programmer’s Pyramid</a>, which teaches the fundamentals of programming, and co-created <a href="https://www.howtolearneffectively.com/">Principles of Learning</a>.
        </p>

        <p>
        You can follow me on <a href="https://twitter.com/amymhaddad">Twitter</a> or email me at: amy@amymhaddad.com. 
        </p>
        

        

       
    
      </div>
    </div>
  </Layout>
)

export default About
